import React from 'react';

const KitComponents = () => {
  const kitItems = [
    { name: 'Controller Board', quantity: '1×', description: 'The "brain" of the project that controls how all the parts work together. It’s like the command center for your circuits.' },
{ name: 'LCD1602 Module (with pin header)', quantity: '1×', description: 'A small screen that shows text or numbers. You can use it to display messages in your project.' },
{ name: 'Breadboard Expansion Board', quantity: '1×', description: 'A larger board to help you add more connections to your project without needing to solder anything. It\'s like a bigger table for your circuit parts.' },
{ name: '4×4 Matrix Keyboard Module', quantity: '1×', description: 'A small keyboard with 16 buttons that you can use to input commands in your projects, like turning something on or off.' },
{ name: '830 tie-points Breadboard', quantity: '1×', description: 'A board used to build circuits without soldering, where you can connect wires and electronic parts easily.' },
{ name: 'Ultrasonic Distance Sensor', quantity: '1×', description: 'A sensor that measures the distance between it and an object using sound waves. It\'s like a bat\'s way of seeing in the dark!' },
{ name: 'Servo Motor (SG90)', quantity: '1×', description: 'A small motor that can rotate to a specific angle. It’s great for moving things in a precise way.' },
{ name: 'Potentiometer (10k)', quantity: '2×', description: 'A knob that you can turn to change things like brightness or volume. It\'s like a volume knob on a speaker.' },
{ name: 'DHT-11 (Temperature and Humidity Sensor)', quantity: '1×', description: 'A sensor that measures temperature and humidity (how wet or dry the air is).' },
{ name: '9V 1A Power Supply', quantity: '1×', description: 'A plug that gives your project the power it needs to run. It\'s like the plug you use to charge your phone.' },
{ name: 'Touch Sensor', quantity: '1×', description: 'A sensor that reacts when you touch it, like a button that knows when you\'re pressing it.' },
{ name: '5V Relay', quantity: '1×', description: 'A part that lets you control high-power things (like lights or motors) with low power from your project.' },
{ name: 'Power Supply Module', quantity: '1×', description: 'A component that helps convert and manage the power needed for your project.' },
{ name: 'Joystick Module', quantity: '1×', description: 'A small controller with a stick that you can push around to control things, like in video games.' },
{ name: 'ULN2003 Stepper Motor Driver Board', quantity: '1×', description: 'A part that helps control stepper motors, which can move in small steps for precision movements.' },
{ name: '74HC595', quantity: '1×', description: 'A special chip that helps you control lots of LEDs with fewer pins on your board.' },
{ name: '4 Digit 7-Segment Display', quantity: '1×', description: 'A display that shows numbers with 4 separate digits, like a small clock or timer.' },
{ name: '7-Segment Display', quantity: '1×', description: 'A display with 7 parts that can show numbers, like the numbers on a calculator or clock.' },
{ name: 'Stepper Motor', quantity: '1×', description: 'A motor that moves in precise steps, which is useful for making things like a robot arm or a spinning fan.' },
{ name: 'Passive/Active Buzzer', quantity: '1×', description: 'A part that makes sound when it gets power, like a tiny alarm or a beep when you press a button.' },
{ name: 'Button (small)', quantity: '5×', description: 'A small button you can press to turn things on or off in your project.' },
{ name: 'Tilt Switch', quantity: '1×', description: 'A switch that turns on or off when it tilts, like a motion detector.' },
{ name: '2N2222 Transistors', quantity: '2×', description: 'Parts that help control electricity flow in your circuits, like a gatekeeper for the current.' },
{ name: 'IR Receiver', quantity: '1×', description: 'A sensor that can receive signals from a remote control, like when you change the channel on the TV.' },
{ name: 'RGB LED', quantity: '1×', description: 'A special LED that can show many colors by mixing red, green, and blue light.' },
{ name: 'Photoresistor', quantity: '2×', description: 'A sensor that changes its resistance depending on how much light it gets, like a light detector.' },
{ name: 'Thermistor', quantity: '1×', description: 'A type of resistor that changes its resistance with temperature, like when things get hotter or colder.' },
{ name: 'Green LED', quantity: '5×', description: 'A small light that glows green when powered.' },
{ name: 'Blue LED', quantity: '5×', description: 'A small light that glows blue when powered.' },
{ name: 'Red LED', quantity: '5×', description: 'A small light that glows red when powered.' },
{ name: 'White LED', quantity: '5×', description: 'A small light that glows white when powered.' },
{ name: 'Yellow LED', quantity: '5×', description: 'A small light that glows yellow when powered.' },
{ name: 'Resistor (100Ω)', quantity: '10×', description: 'A part that resists the flow of electricity, helping control how much power goes through other parts.' },
{ name: 'Resistor (100KΩ)', quantity: '10×', description: 'A larger resistor that allows very little electricity to flow through it.' },
{ name: 'Resistor (10Ω)', quantity: '10×', description: 'A small resistor that lets more electricity flow through it.' },
{ name: 'Resistor (2KΩ)', quantity: '10×', description: 'A resistor that helps limit the flow of electricity to a safe amount.' },
{ name: 'Resistor (1KΩ)', quantity: '10×', description: 'A resistor that helps slow down the flow of electricity, often used to protect other parts.' },
{ name: 'Resistor (220Ω)', quantity: '10×', description: 'A resistor that’s commonly used with LEDs to prevent them from getting too much electricity.' },
{ name: 'Resistor (330Ω)', quantity: '10×', description: 'A resistor used to help protect components like LEDs from too much power.' },
{ name: 'Resistor (1.5KΩ)', quantity: '10×', description: 'A resistor that helps balance the current in your circuits, keeping everything running smoothly.' },
{ name: 'Resistor (1MΩ)', quantity: '10×', description: 'A very high-resistance resistor, used in circuits where little current is needed.' },
{ name: 'Diode Rectifier', quantity: '2×', description: 'A part that lets electricity flow in one direction only, like a one-way street for electricity.' },
{ name: 'Fan Motor Fan', quantity: '1×', description: 'A small motor that powers a fan to blow air, like a mini cooling fan.' },
{ name: 'Jumper Wires', quantity: '65×', description: 'Wires with connectors at both ends that let you easily make connections between parts on a breadboard.' },
{ name: 'USB Cable', quantity: '1×', description: 'A cable used to connect your project to your computer or power source.' },
{ name: 'Male to Female Jumper Wires', quantity: '1×', description: 'Wires that let you connect male and female parts together on your breadboard.' },
{ name: 'IR Remote Control', quantity: '1×', description: 'A remote control that sends signals to control things in your project, like turning lights on or off.' }

  ];

  return (
    <div style={{ padding: '20px', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
         <img
          src="/images/Logo4c.png"
          alt="Kit Components"
          style={{ maxWidth: '100px', height: 'auto' }}
        />
        <h2>Sparkers Kit v1.1</h2>
      <p>Here’s everything you should find in your kit. Check the list and the picture at the bottom of the page to make sure you have all the parts you need before getting started!</p>
      <table
        style={{
          margin: '0 auto',
          borderCollapse: 'collapse',
          width: '80%',
        }}
        border="1"
      >
        <thead>
          <tr>
            <th style={{ padding: '10px', backgroundColor: '#f4f4f4' }}>Item</th>
            <th style={{ padding: '10px', backgroundColor: '#f4f4f4' }}>Description</th>
            <th style={{ padding: '10px', backgroundColor: '#f4f4f4' }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {kitItems.map((item, index) => (
            <tr key={index}>
              <td style={{ padding: '10px' }}>{item.name}</td>
              <td style={{ padding: '10px' }}>{item.quantity}</td>
              <td style={{ padding: '10px' }}>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ marginTop: '20px' }}>
        <img
          src="/images/kitall.jpg"
          alt="Kit Components"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    </div>
  );
};

export default KitComponents;
