import React, { useEffect, useState } from 'react';
import { BASE_URL, API_GET_PROJECT_BY_STUDENT, API_ENDPOINT_GET_PROJECT_STEPS_FOR_STUDENT } from '../../../Shared/Config'; 
import ProjectProgressBar from './ProgressBar';

const ProjectDetails = ({ loadedProjectId, studentId, onStepSelect, onProjectClose }) => {
    
    const [projectDetails, setProjectDetails] = useState(null);
    const [projectSteps, setProjectSteps] = useState([]);

    useEffect(() => {
        // Fetch project details
        
        const fetchProjectDetails = async () => {
            
            const url = `${BASE_URL}${API_GET_PROJECT_BY_STUDENT}?student_id=${studentId}&project_id=${loadedProjectId}`;
            console.log(url);
            const response = await fetch(url);
            
            const data = await response.json();
           
            setProjectDetails(data[0]);
        };        

        // Fetch project steps
        const fetchProjectSteps = async () => {
            let url = `${BASE_URL}${API_ENDPOINT_GET_PROJECT_STEPS_FOR_STUDENT}?student_id=${studentId}&project_id=${loadedProjectId}`;
              
            const response = await fetch(url);
            const data = await response.json();
            setProjectSteps(data);    
        };

        fetchProjectDetails();
        fetchProjectSteps();
    }, [loadedProjectId, studentId]);

    const handleStepClick = (step) => {
        if (step.IsStepListedForStudent > 0) {
            onStepSelect(step);
        }
    };

    const onProjectCloseClick = () => {
       var json = {"actionType": "closeProject"};
       onProjectClose(json);
    };


    return (
        <div>
            <button className="main-button main-button-green" onClick={onProjectCloseClick}>Back To Profile</button>
            
            
            {projectDetails && (
                <div>
                    <ProjectProgressBar percentage={projectDetails.CompletionPercentage} />
                  
                    <div className="article-title-container">Project: {projectDetails.ProjectTitle}</div>
                    <p>{projectDetails.ProjectDescription}</p>
                    
                </div>
            )}
            {projectDetails && projectDetails.CompletionPercentage === 100 && (
                <div style={{ color: 'green', fontWeight: 'bold' }}>
                    PROJECT COMPLETE
                </div>
            )}
            
            <h2>Project Steps:</h2>
            <ul>
            {projectSteps.map((step, index) => {

  return (
    <div key={step.ProjectStepId}>
      <button
  className={`main-button ${
    step.StepStatus == 0 ? 'main-button-green' : step.IsStepListedForStudent > 0 ? 'main-button-green-old' : '!'
  }`}
  onClick={() => handleStepClick(step)}
>
  {step.StepDescription}
  
</button>

    </div>
  );
})}




            </ul>
        </div>
    );
}

export default ProjectDetails;
